import React from "react";

const Shield = ({
    opacity
}) => {
	return (
		<svg style={{opacity: opacity}} version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 194.291 194.291" >
				<g>
					<path d="M157.208,119.194l-37.213,13.072l-6.869-15.424c-0.681-1.528-1.969-2.703-3.553-3.241s-3.321-0.39-4.792,0.409
						l-55.472,30.136c-2.842,1.544-3.948,5.065-2.501,7.958c0.206,0.412,0.458,0.798,0.753,1.152
						c12.811,15.411,28.426,28.912,46.41,40.126c0.972,0.606,2.073,0.909,3.175,0.909s2.203-0.303,3.175-0.909
						c27.299-17.022,48.858-39.072,64.079-65.535c1.352-2.351,0.958-5.318-0.96-7.236C161.808,118.98,159.386,118.43,157.208,119.194z
						M97.146,181.178c-13.399-8.714-25.318-18.794-35.543-30.054l43.202-23.47l6.478,14.547c1.271,2.855,4.52,4.255,7.47,3.22
						l25.919-9.105C132.103,153.766,116.181,168.801,97.146,181.178z"/>
					<path d="M188.493,32.878c-1.133-1.19-2.704-1.863-4.346-1.863c-0.046,0.001-0.093,0-0.139,0c-6.422,0-12.409-2.564-16.869-7.229
						c-4.528-4.735-6.844-10.947-6.521-17.492c0.081-1.641-0.514-3.243-1.647-4.432C157.837,0.673,156.267,0,154.624,0H39.667
						c-1.643,0-3.213,0.673-4.346,1.863c-1.133,1.189-1.728,2.792-1.647,4.432c0.323,6.544-1.993,12.756-6.521,17.492
						c-4.46,4.664-10.447,7.229-16.869,7.229c-0.037,0-0.074,0-0.111,0c-1.699-0.011-3.235,0.667-4.374,1.863s-1.734,2.811-1.645,4.46
						c1.329,24.612,7.598,61.318,29.918,96.415c1.138,1.79,3.077,2.781,5.067,2.781c0.97,0,1.952-0.235,2.86-0.729l73.936-40.167
						l8.085,19.692c1.208,2.943,4.504,4.431,7.508,3.393l41.602-14.362c1.672-0.577,3.005-1.863,3.641-3.514
						c7.67-19.908,12.167-41.276,13.368-63.51C190.227,35.688,189.631,34.075,188.493,32.878z M166.55,93.938l-33.582,11.594
						l-8.473-20.635c-0.647-1.576-1.934-2.803-3.54-3.374c-1.604-0.572-3.377-0.433-4.875,0.38l-74.808,40.64
						C24.276,93.75,18.327,64.19,16.531,42.471c7.321-1.296,14.017-4.872,19.294-10.39c5.268-5.509,8.609-12.597,9.582-20.081h103.478
						c0.973,7.484,4.313,14.572,9.582,20.081c5.278,5.52,11.974,9.095,19.297,10.39C176.295,60.411,172.533,77.684,166.55,93.938z"/>
				</g>
		</svg>
	)
}

export default Shield;